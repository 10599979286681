var menuBeginnerButton = document.getElementById('menuBeginnerButton');
var menuBeginnerContainer = document.getElementById('menuBeginnerContainer');
var menuBeginnerHeader = document.getElementById('menuBeginnerHeader');

menuBeginnerButton.addEventListener('click', function(event) {

    menuBeginnerContainer.classList.toggle("main-menu-beginner-container--open");
    menuBeginnerButton.classList.toggle("menu-button--close");
    menuBeginnerHeader.classList.toggle("text-decoration-underline");
    
  }, false);
  
  

var menuMemberButton = document.getElementById('menuMemberButton');
var menuMemberContainer = document.getElementById('menuMemberContainer');
var menuMemberHeader = document.getElementById('menuMemberHeader');

menuMemberButton.addEventListener('click', function(event) {

    menuMemberContainer.classList.toggle("main-menu-member-container--open");
    menuMemberButton.classList.toggle("menu-button--close");
    menuMemberHeader.classList.toggle("text-decoration-underline");
    
  }, false);
  


var menuSocietyButton = document.getElementById('menuSocietyButton');
var menuSocietyContainer = document.getElementById('menuSocietyContainer');
var menuSocietyHeader = document.getElementById('menuSocietyHeader');

menuSocietyButton.addEventListener('click', function(event) {

    menuSocietyContainer.classList.toggle("main-menu-society-container--open");
    menuSocietyButton.classList.toggle("menu-button--close");
    menuSocietyHeader.classList.toggle("text-decoration-underline");
    
  }, false);